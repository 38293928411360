const state = {
	currentUser: JSON.parse(localStorage.getItem('currentUser')),
	currentInstitution: JSON.parse(localStorage.getItem('currentInstitution')),
	accessToken: localStorage.getItem('accessToken'),
	refreshToken: localStorage.getItem('refreshToken'),
	// tempParentData: JSON.parse(localStorage.getItem('tempParentData')),
}

const getters = {
	accessToken: state => state.accessToken,
	refreshToken: state => state.refreshToken,
	currentUser: state => state.currentUser,
	currentInstitution: state => state.currentInstitution,
	// tempParentData: state => state.tempParentData
}

const actions = {

	async setTokens({
		commit
	}, tokens) {
		await commit('saveTokens', tokens);
	},

	// sets current user
	async setCurrentUser({
		commit
	}, currentUser) {
		commit('saveCurrentUser', currentUser);
	},

	// sets current user
	async setCurrentInstitution({
		commit
	}, currentInstitution) {
		commit('saveCurrentInstitution', currentInstitution);
	},

	async clearTokens({ commit }) {
		commit('removeToken');
	},


	// Executed while LOGOUT
	async logoutUser({
		commit
	}) {
		// IMP : Also Send logout info to backend to end session 
		commit('removeCurrentUser');
		commit('removeToken');
		commit('removeEverything');
        commit('clearDashboardData');

	},

	async unsetCurrentUser({
		commit
	}) {
		commit('removeCurrentUserFromState');

	},

	// async saveTempParentData({ commit }){
	// 	await commit('setTempParentData');
	// }

}

const mutations = {
	saveCurrentUser(state, currentUser) {
		localStorage.setItem('currentUser', JSON.stringify(currentUser));
		state.currentUser = currentUser;
	},

	saveCurrentInstitution(state, currentInstitution) {
		localStorage.setItem('currentInstitution', JSON.stringify(currentInstitution));
		state.currentInstitution = currentInstitution;
	},

	removeCurrentUser(state) {
		localStorage.removeItem('currentUser');
		state.currentUser = null;
	},

	removeCurrentUserFromState(state) {
		state.currentUser = null;
	},

	saveTokens(state, tokenData) {
		localStorage.setItem('accessToken', tokenData.access);
		localStorage.setItem('refreshToken', tokenData.refresh);
		state.accessToken = tokenData.access;
		state.refreshToken = tokenData.refresh;
	},

	removeToken(state) {
		localStorage.removeItem('accessToken');
		localStorage.removeItem('refreshToken');
		state.token = null;
	},

	removeEverything(state) {
		localStorage.clear();
		state.accessToken = null;
		state.refreshToken = null;
		state.token = null;
		state.currentUser = null;
		state.currentInstitution = null;
		state.quickLinks = null;
		state.dashboardData = null;
		state.tempParentData = null;
	},

	// setTempParentData(state) {
	// 	var tempData = {
	// 		currentUser: state.currentUser, 
	// 		currentInstitution: state.currentInstitution,
	// 		accessToken: state.accessToken,
	// 		refreshToken: state.refreshToken
	// 	};	
	// 	state.tempParentData = tempData;
	// 	localStorage.setItem("tempParentData", JSON.stringify(tempData));
	// 	state.accessToken = state.refreshToken = state.currentUser = state.currentInstitution = null;
	// 	localStorage.removeItem('accessToken');
	// 	localStorage.removeItem('refreshToken');
	// 	localStorage.removeItem('currentUser');
	// 	localStorage.removeItem('currentInstitution');
	// },

}


export default {
	state,
	getters,
	actions,
	mutations
};