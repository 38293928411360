<template>
  <v-dialog v-model="visible" max-width="500" persistent>
    <v-card>
      <v-card-title class="headline text-left mb-0">{{ title }}</v-card-title>
      <v-card-title v-if="subtitle" class="text-left">{{ subtitle }}</v-card-title>

      <v-card-text class="text-left" v-if="description">{{ description }}</v-card-text>
      <slot></slot>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          v-if="failureText"
          :disabled="isLoading"
          color="green darken-1"
          text
          @click="$emit('failureCallback')"
        >{{ failureText }}</v-btn>
        <v-btn color="green darken-1" :disabled="disableSuccessButton || isLoading" text @click="$emit('successCallback')">{{ successText }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: "ConfirmationDialog",
  props: [
    "visible",
    "title",
    "subtitle",
    "description",
    "successText",
    "failureText",
    "disableSuccessButton",
  ],
  computed:  {
    ...mapGetters(['isLoading']),
  },
  methods: {},
  
};
</script>

<style>
.v-card__text,
.v-card__title {
  word-break: normal !important;
}
</style>
