const state = {
    // adminRoom: {
    //     room: null,
    //     roomName: "",
    //     firstSection: null,
    //     secondSection: null,
    //     thirdSection: null,
    // },
    isLoading: false,
    drawer: true,
    // dashboardData: {
    //     counts: null,
    //     notices: null,
    //     logs: null,
    //     events: null,
    //     feeCounts: {
    //         paid: 0,
    //         due: 0,
    //         overdue: 0,
    //     },
    // },
    // tourFlags: null,
}

const getters = {
    // adminRoom: state => state.adminRoom,
    isLoading: state => state.isLoading,
    drawer: state => state.drawer,
    // dashboardData: state => state.dashboardData,
    // tourFlags: state => state.tourFlags,
}

const actions = {
    setLoading({
        commit
    }, loading) {
        commit('saveLoading', loading);
    },

    setSections({
        commit
    }, sectionsData) {
        commit('saveSections', sectionsData);
    },

    // setAdminRoom({
    //     commit
    // }, roomData) {
    //     commit('saveAdminRoom', roomData);
    // },
    // clearAdminRoom({
    //     commit
    // }) {

    //     commit('clearAdminRoom');
    // },
    toggleDrawer({
        commit
    }) {
        commit('toggleDrawer');
    },
    closeDrawer({
        commit
    }) {
        commit('closeDrawer');
    },
    // setDashboarData({
    //     commit
    // }, dashboardData) {
    //     commit('saveDashboardData', dashboardData);
    // },
    // clearDashboardData({
    //     commit
    // }) {
    //     commit('clearDashboardData');
    // },
    // setTourFlags({
    //     commit
    // }, tourFlags) {
    //     commit('saveTourFlags', tourFlags);
    // },
}

const mutations = {
    saveLoading(state, loading) {
        state.isLoading = loading;
    },
    saveSections(state, sectionsData) {
        state.adminRoom.firstSection = sectionsData.firstSection;
        state.adminRoom.secondSection = sectionsData.secondSection;
        state.adminRoom.thirdSection = sectionsData.thirdSection;
    },
    // saveAdminRoom(state, roomData) {
    //     state.adminRoom = roomData;
    // },
    // clearAdminRoom(state) {
    //     state.adminRoom.room = null;
    //     state.roomName = null;
    //     state.firstSection = null;
    //     state.secondSection = null;
    //     state.thirdSection = null;
    //     state.adminRoom = {
    //         room: null,
    //         roomName: "",
    //         firstSection: null,
    //         secondSection: null,
    //         thirdSection: null,
    //     };
    // },
    toggleDrawer(state) {
        state.drawer = !state.drawer;
    },
    closeDrawer(state) {
        state.drawer = false;
    },
    // saveDashboarData(state, dashboardData) {
    //     state.dashboardData = dashboardData;
    // },
    // saveTourFlags(state, tourFlags) {
    //     state.tourFlags = tourFlags;

    // },
    // clearDashboardData(state) {
    //     state.dashboardData = {
    //         counts: null,
    //         notices: null,
    //         logs: null,
    //         events: null,
    //         feeCounts: {
    //             paid: 0,
    //             due: 0,
    //             overdue: 0,
    //         },
    //     };
    // },
}

export default {
    state,
    getters,
    actions,
    mutations
}