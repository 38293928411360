const state = {
    feeSessionData: [],
    activeFeeSession : JSON.parse(localStorage.getItem('activeFeeSession')),
}

const getters = {
    feeSessionData: state => state.feeSessionData,
    activeFeeSession: state => state.activeFeeSession
}

const actions = {
    setFeeSessionData({ commit }, feeSessionData) {
        commit('saveFeeSessionData', feeSessionData);
        var activeFeeSession = JSON.parse(localStorage.getItem('activeFeeSession'))
        console.log(activeFeeSession)
        if(!activeFeeSession){
            activeFeeSession  = feeSessionData.find((x) => x.active);
            if (!activeFeeSession) return;
            commit('saveActiveSession', activeFeeSession);
        }
    },
    setActiveSessionData({commit}, activeSession){
        commit('saveActiveSession', activeSession);
    },
    clearFeeSessionData({ commit }){
        commit('removeFeeSessionData');
    }
}

const mutations = {
    saveFeeSessionData(state, feeSessionData){
        state.feeSessionData = feeSessionData;
    },
    saveActiveSession(state, activeFeeSession){
        localStorage.setItem('activeFeeSession',JSON.stringify(activeFeeSession))
        state.activeFeeSession = activeFeeSession;
    },
    removeFeeSessionData(state){
        state.feeSessionData = [];
        state.activeFeeSession = null;
        localStorage.removeItem('activeFeeSession');
        localStorage.removeItem('feeSessionData');
    }
}

export default {
    state, 
    getters,
    actions,
    mutations
}