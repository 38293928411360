<template>
  <div>
    <ConfirmationDialog
      title="Are you sure you want to change Your Password ?"
      successText="Proceed"
      :disableSuccessButton="!changePassword"
      @successCallback="submitChangePasswordDetails"
      @failureCallback="closeDialog()"
      failureText="Cancel"
      :visible="visible"
    >
      <slot>
        <v-form v-model="changePassword" ref="form">
          <v-text-field
            v-model="currentPassword"
            class="rounded-input mx-5 my-4"
            outlined
            dense
            clearable
            hide-details="auto"
            :error-messages="formErrors.currentPasswordError"
            @input="formErrors.currentPasswordError = null"
            placeholder="Current Password"
            :rules="[rules.required]"
            :append-icon="typeValue ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="() => (typeValue = !typeValue)"
            :type="typeValue ? 'password' : 'text'"
          />
          <v-text-field
            v-model="newPassword"
            class="rounded-input mx-5 my-4"
            outlined
            dense
            :error-messages="formErrors.newPasswordError"
            @input="formErrors.newPasswordError = null"
            clearable
            hide-details="auto"
            placeholder="New Password"
            :rules="[rules.pwRequired, rules.existingSamePassword]"
            :append-icon="typeValueNew ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="() => (typeValueNew = !typeValueNew)"
            :type="typeValueNew ? 'password' : 'text'"
          />
          <v-text-field
            v-model="checkPassword"
            class="rounded-input mx-5 my-4"
            outlined
            dense
            hide-details="auto"
            :error-messages="formErrors.confirmPasswordError"
            @input="formErrors.confirmPasswordError = null"
            clearable
            placeholder="Confirm New Password"
            :rules="[rules.pwRequired, rules.samePassword]"
            :append-icon="typeValueCheck ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="() => (typeValueCheck = !typeValueCheck)"
            :type="typeValueCheck ? 'password' : 'text'"
          />
        </v-form>
      </slot>
    </ConfirmationDialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ConfirmationDialog from "@components/widgets/dialogs/ConfirmationDialog";
import api from "@api/index";
import endpoints from "@api/repository";

export default {
  name: "ChangePasswordDialog",
  props: ["visible"],
  components: {
    ConfirmationDialog,
  },
  data() {
    return {
      changePassword: true,
      typeValue: String,
      typeValueNew: String,
      typeValueCheck: String,
      currentPassword: null,
      checkPassword: null,
      newPassword: null,
      snackbar: {
        visible: false,
        text: "",
      },
      formErrors: {
        currentPasswordError: null,
        newPasswordError: null,
        confirmPasswordError: null,
      },
      rules: {
        required: (v) => !!v || "Required",
        pwRequired: (v) =>
          /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/.test(
            v
          ) ||
          "Password must contain - At least one Upper Case,One Digit, one AlphaNumeric Character",
        samePassword: (v) =>
          v == this.newPassword ||
          "Confirm Password must be same as New Password",
        existingSamePassword: (v) =>
          v != this.currentPassword || "New Password Same as Current Password",
      },
    };
  },
  computed: {
    ...mapGetters(["isLoading", "accessToken", "currentUser"]),

    apiEssentials() {
      return {
        accessToken: this.accessToken,
        handleErrorsFunction: this.handleApiErrors,
        setLoading: this.setLoading,
      };
    },
  },
  methods: {
    ...mapActions(["setLoading"]),
    validatePassword() {
      if (this.currentPassword == this.newPassword) {
        this.formErrors.newPasswordError =
          "Password is same as Current Password";
        return false;
      }
      if (this.checkPassword != this.newPassword) {
        this.formErrors.confirmPasswordError =
          "Confirm Password must be same as New Password";
        return false;
      } else {
        return true;
      }
    },
    
    closeDialog(){
      this.$refs.form.reset();
      this.$emit('closeChangePasswordDialog');
    },

    async submitChangePasswordDetails() {
      if (!this.validatePassword()) return;
      var body = {
        current_password: btoa(this.currentPassword),
        new_password: btoa(this.newPassword),
      };
      var response = await api.call(
        this.apiEssentials,
        endpoints.changePassword,
        api.Methods.POST,
        body
      );
      if (response) {
        this.$refs.form.reset();
        this.$emit("passwordChangeSuccessful");
      }
    },

    async handleApiErrors(err) {
      console.log(err);
      this.setLoading(false);
      if (err.response) {
        if (err.response.status == 401) {
          this.formErrors.currentPasswordError =
            "The current password you entered is wrong.";
        } else if (err.response.status == 409) {
          this.formErrors.newPasswordError =
            "New password should be different than current password";
        }
      } else {
        this.snackbar.text = "Unknown error occurred";
        this.snackbar.visible = true;
      }
    },
  },
};
</script>