<template>
  <v-app>
    <div id="app">
      <AppBar
        :isLoading="isLoading"
        v-if="currentUser && currentInstitution"
        :isSuperInstitution="currentInstitution.is_super_institution"
      />
      <v-main>
        <transition name="slide-fade" mode="out-in">
          <router-view class="view" />
        </transition>
      </v-main>
    </div>
  </v-app>
</template>

<script>
import AppBar from "@components/widgets/AppBar.vue";
// import api from "@api/index";
// import endpoints from "@api/repository";
import { mapGetters, mapActions } from "vuex";

export default {
  name: 'App',
  data: () => ({
    //
  }),
  computed: {
    ...mapGetters([
      "accessToken",
      "currentUser",
      "currentInstitution",
      "isLoading",
      "feeSessionData",
      "activeFeeSession",
      // "tourFlags",
    ]),
    essentials() {
      return {
        setLoading: this.setLoading,
        accessToken: this.accessToken,
        handleErrorsFunction: (err) => {
          this.setLoading(false);
          console.log(err);
        },
      };
    },
  },
  components: {
    AppBar,
  },
  methods:{
    ...mapActions([
      "setLoading",
      "setCurrentUser",
      "setCurrentInstitution",
      "setFeeSessionData",
      "setActiveSessionData",
      "setTourFlags",
    ]),
  }
};
</script>

<style>
/* @import "~shepherd.js/dist/css/shepherd.css"; */
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
/* 
.shepherd-element {
  margin: 5px !important;
  font-family: "Montserrat", sans-serif;
} */
/* .shepherd-text {
  margin: 10px 0px !important;
} */
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* background-color: #F6F6F6; */
  /* background-image: url('https://cdn.vuetifyjs.com/images/parallax/material.jpg'); */
}
span.required::after {
  content: "*";
  font-size: 18px;
  color: red;
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
.rounded-input {
  border-radius: 12px !important;
}

/* Stripped Data table Rows 
tbody tr:nth-of-type(odd) {
   background-color: #f8f8f8;
   
 } */

/* tbody tr:hover {
   background-color: red !important;
    
 } */
</style>