import axios from 'axios';
import Helper from '@utils/misc';


const Methods = Object.freeze({ "GET": 1, "POST": 2, "DELETE": 3, "PUT": 4, "PATCH": 5 })

async function sendHttpRequest({ url, method = Methods.GET, token, body }) {
    //hr= handle response
    let hr = (res) => {
        if (res instanceof Error) return he(res);
        if(res.status == 204) return true;
        return res.data;
    }
 
    //he = handle error
    let he = (err) => {
        return err;
    }
 
    var response;
    // checks for the HttpMethod and sends request to the server accordingly
    if(method == Methods.POST){
        response =  await axios.post(url, body, {
            headers: {
                Authorization: token ? 'JWT ' + token : null
            }
        }).then((res) => hr(res)).catch((err) => he(err))
    }
    else if(method == Methods.PUT){
        response =  await axios.put(url, body, {
            headers: {
                Authorization: token ? 'JWT ' + token : null
            }
        }).then((res) => hr(res)).catch((err) => he(err))
    }else if(method == Methods.DELETE){
        response = await axios.delete(url, {
            headers: {
                Authorization: token ? 'JWT ' + token : null
            },
        }).then((res) => hr(res)).catch((err) => he(err))
    }
    else if(method == Methods.PATCH){
        response = await axios.patch(url, body,{
            headers: {
                Authorization: token ? 'JWT ' + token : null
            },
        }).then((res) => hr(res)).catch((err) => he(err))
    }
    else{
        response = await axios.get(url, {
            headers: {
                'Authorization': token ? 'JWT ' + token : null,
            }
        }).then((res) => hr(res)).catch((err) => he(err));
    }
    // let response = (method == Methods.POST) ?
    //     await axios.post(url, body, {
    //         headers: {
    //             Authorization: token ? 'JWT ' + token : null
    //         }
    //     }).then((res) => hr(res)).catch((err) => he(err))
    //     : await axios.get(url, {
    //         headers: {
    //             'Authorization': token ? 'JWT ' + token : null,
    //         }
    //     }).then((res) => hr(res)).catch((err) => he(err));
    return response;
}
 
async function handleApi({ apiEssentials, responseVar, url, method = Methods.GET, body }) {
    apiEssentials.setLoading(true);
    // gets test details
    let responseFromServer = await sendHttpRequest({ url: url, token: apiEssentials.accessToken, method: method, body: body });
    if (!Helper.handleErrors(responseFromServer, apiEssentials.handleErrorsFunction)) return responseVar;
 
    // display
    responseVar = responseFromServer;
 
    apiEssentials.setLoading(false);
    return responseVar;
}
 
async function call(apiEssentials, url, method = Methods.GET, body = {}){
    var response;
    response = await handleApi({
        apiEssentials: apiEssentials,
        responseVar: response,
        url: url,
        method: method,
        body: body
    });
    if(response != null) return response;
    else return null;
}
 
let ifiHttp = {
    Methods,
    sendHttpRequest,
    handleApi,
    call,
}
 
export default ifiHttp;