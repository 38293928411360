const state = {
    feeCollectionData: {}
}

const getters = {
    feeCollectionData: state => state.feeCollectionData
}

const actions = {
    setFeeCollectionData({ commit }, feeCollectionData) {
        commit('saveFeeCollectionData', feeCollectionData);
    },
    clearFeeCollectionData({ commit }){
        commit('removeFeeCollectionData');
    }
}

const mutations = {
    saveFeeCollectionData(state, feeCollectionData){
        state.feeCollectionData = feeCollectionData;
    },
    removeFeeCollectionData(state){
        state.feeCollectionData = null;
    }
}

export default {
    state, 
    getters,
    actions,
    mutations
}