import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth';
import base from './modules/base';
import feeSession from './modules/fees/feeSession';
import collectfee from './modules/fees/collectfee';


Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
    },
    mutations: {
    },
    actions: {
    },
    modules: {
      base,
      auth,
      feeSession,
      collectfee,
    //   feeSession,
    //   tour,
    //   utils,
    }
})

export default store;
