import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import './assets/styles/global-variables.sass';
import store from './store';
import axios from 'axios';
import authApi from '@api/auth/base';

Vue.config.productionTip = false

axios.interceptors.response.use(res => {
  return res;
}, async err => {
  const { config, response: { status, data } } = err;
  const originalRequest = config;
  let isObjectEmpty = (obj) => Object.keys(obj).length === 0 && obj.constructor === Object;
  if (!config.headers.Authorization || isObjectEmpty(config.headers.Authorization)) {
    return Promise.reject(err);
  }

  if (err.response && status == 401 && data.code == "token_not_valid") {
    // debugger;

    // check if refreshToken exists, if not logout
    let refreshToken = store.getters.refreshToken;
    if (refreshToken == null) {
      store.dispatch('logoutUser');
      return;
    }
    // if exists, send to server and get new access token
    store.dispatch('setLoading', true);
    let accessTokenResponse = await authApi.renewAccessToken(refreshToken);
    store.dispatch('setLoading', false);
    if (accessTokenResponse instanceof Error) {
      if (accessTokenResponse.response.status == 401) {
        store.dispatch('logoutUser');
        this.$router.push('/logout');
      }
      return;
    }
    store.dispatch('setTokens', {
      refresh: refreshToken,
      access: accessTokenResponse.access
    });

    if (originalRequest) {
      originalRequest.headers.Authorization = "JWT " + accessTokenResponse.access;
      return axios.request(originalRequest);
    }

  }

  return err;

});

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App),
  components: {App},
}).$mount('#app')
