// import searchItems from '@utils/search_items';
// import store from '../store/index';

function frac(f) {
  return f % 1;
}

function convert_number(number) {
  if ((number < 0) || (number > 999999999)) {
    return "NUMBER OUT OF RANGE!";
  }
  var Gn = Math.floor(number / 10000000); /* Crore */
  number -= Gn * 10000000;
  var kn = Math.floor(number / 100000); /* lakhs */
  number -= kn * 100000;
  var Hn = Math.floor(number / 1000); /* thousand */
  number -= Hn * 1000;
  var Dn = Math.floor(number / 100); /* Tens (deca) */
  number = number % 100; /* Ones */
  var tn = Math.floor(number / 10);
  var one = Math.floor(number % 10);
  var res = "";

  if (Gn > 0) {
    res += (convert_number(Gn) + " CRORE");
  }
  if (kn > 0) {
    res += (((res == "") ? "" : " ") +
      convert_number(kn) + " LAKH");
  }
  if (Hn > 0) {
    res += (((res == "") ? "" : " ") +
      convert_number(Hn) + " THOUSAND");
  }

  if (Dn) {
    res += (((res == "") ? "" : " ") +
      convert_number(Dn) + " HUNDRED");
  }


  var ones = Array("", "ONE", "TWO", "THREE", "FOUR", "FIVE", "SIX", "SEVEN", "EIGHT", "NINE", "TEN", "ELEVEN", "TWELVE", "THIRTEEN", "FOURTEEN", "FIFTEEN", "SIXTEEN", "SEVENTEEN", "EIGHTEEN", "NINETEEN");
  var tens = Array("", "", "TWENTY", "THIRTY", "FOURTY", "FIFTY", "SIXTY", "SEVENTY", "EIGHTY", "NINETY");

  if (tn > 0 || one > 0) {
    if (!(res == "")) {
      res += " AND ";
    }
    if (tn < 2) {
      res += ones[tn * 10 + one];
    } else {

      res += tens[tn];
      if (one > 0) {
        res += ("-" + ones[one]);
      }
    }
  }

  if (res == "") {
    res = "zero";
  }
  return res;
}



const Helper = {
  handleErrors(errorResponse, handler) {
    if (errorResponse instanceof Error) {
      console.log(errorResponse.response);
      handler(errorResponse);
      return false;
    }
    return true;
  },
  // getRoomName(room) {
  //     let title = "";
  //     if (room == null) return title;

  //     if (room.first_section != null) {
  //         title = title + `${room.first_section.title}`;
  //     }
  //     if (room.second_section != null) {
  //         title = title + ` | ${room.second_section.title}`;
  //     }
  //     if (room.third_section != null) {
  //         title = title + ` | ${room.third_section.title}`;
  //     }
  //     return title;
  // },
  async toBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  },

  getExportName() {
    return "export_" + new Date().toLocaleDateString()
  },
  // uuidv4() {
  //     return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
  //         var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
  //         return v.toString(16);
  //     });
  // }
  totalSum(items, prop) {

    if (items == null) {
      return 0;
    }
    return items.reduce(function (a, b) {
      return b[prop] == null ? parseFloat(a || 0) : parseFloat(a || 0) + parseFloat(b[prop] || 0);
    }, 0);
  },

  currencyFormat(amount) {
    var formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "INR",
    });
    return formatter.format(amount);
  },

  inWords(num) {
    var a = ['', 'one ', 'two ', 'three ', 'four ', 'five ', 'six ', 'seven ', 'eight ', 'nine ', 'ten ', 'eleven ', 'twelve ', 'thirteen ', 'fourteen ', 'fifteen ', 'sixteen ', 'seventeen ', 'eighteen ', 'nineteen '];
    var b = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];
    if ((num = num.toString()).length > 9) return 'overflow';
    var n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    if (!n) return;
    var str = '';
    str += (n[1] != 0) ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'crore ' : '';
    str += (n[2] != 0) ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'lakh ' : '';
    str += (n[3] != 0) ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'thousand ' : '';
    str += (n[4] != 0) ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'hundred ' : '';
    str += (n[5] != 0) ? ((str != '') ? 'and ' : '') + (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) : '';
    str += 'rupees only ';
    console.log("Amount in words :", str)
    return str;
  },

  number2text(value) {
    var fraction = Math.round(frac(value) * 100);
    var f_text = "";

    if (fraction > 0) {
      f_text = "AND " + convert_number(fraction) + " PAISE";
    }

    return convert_number(value) + " RUPEES " + f_text + " ONLY";
  },




  // Helper.replaceTextFromArray(string, [['aa', 'bb'], ['cc','dd']])
  replaceTextFromArray(main, list_of_pairs) {
    list_of_pairs.map(pair => {
      if (main.includes(pair[0])) {
        main = main.replace(pair[0], pair[1]);
      }
    });

    return main;
  },

  addUrlParams(base_url, params) {
    if (!Array.isArray(params)) params = [params];
    var url = base_url
    params.forEach((param) => {
      url += (url.split("?")[1] ? "&" : "?") + param;
    })
    return url;
  },

  guidGenerator() {
    var S4 = function () {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    };
    return (S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4());
  },

  secondsToHms(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    // var s = Math.floor(d % 3600 % 60);

    // var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
    // var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
    // var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    // return hDisplay + mDisplay + sDisplay; 

    return `${h}:${m}`
  },

  prettySecondsToHms(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? "h:" : "h:") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? "m:" : "m:") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? "s" : "s") : "";
    return hDisplay + mDisplay + sDisplay; 

  },

  getRateFromSeconds(seconds, rate) {
    seconds = Number(seconds)
    var rate_per_second = Number(rate) / 3600
    var amount = seconds * rate_per_second
    return parseFloat(amount).toFixed(2)

  },

  clone(obj) {
    var copy;

    // Handle the 3 simple types, and null or undefined
    if (null == obj || "object" != typeof obj) return obj;

    // Handle Date
    if (obj instanceof Date) {
      copy = new Date();
      copy.setTime(obj.getTime());
      return copy;
    }

    // Handle Array
    if (obj instanceof Array) {
      copy = [];
      for (var i = 0, len = obj.length; i < len; i++) {
        copy[i] = this.clone(obj[i]);
      }
      return copy;
    }

    // Handle Object
    if (obj instanceof Object) {
      copy = {};
      for (var attr in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, attr)) copy[attr] = this.clone(obj[attr]);
      }
      return copy;
    }

    throw new Error("Unable to copy obj! Its type isn't supported.");
  },

  // {
  //   name: "Add Student",
  //   breadcrumb: "Students > Add",
  //   link: "/students/add",
  //   count: 10,
  // }
//   async updateQuickLinks(to) {

//     if (to.fullPath == '/') return;
//     // check if to link is in search items
//     var searchItem = searchItems.find(s => s.link == to.fullPath);
//     if (!searchItem) return;
//     // if yes, update in store
//     store.dispatch('updateQuickLinks', searchItem);
//   },

  obscureEmail(email) {
    var coreEmail = email.split('@')[0];
    var subString = coreEmail.substring(Math.round(coreEmail.length / 2), coreEmail.length);

    email = email.replace(subString, "*".repeat(subString.length));
    return email;
  },

  getCurrentFiscalYearDates() {
    var today = new Date();
    return [
      new Date(today.getMonth() > 2 ? today.getFullYear() : today.getFullYear() - 1, 3, 1),
      new Date(today.getMonth() > 2 ? today.getFullYear() + 1 : today.getFullYear(), 2, 31)
    ];
  },

  getFiscalYear(date) {
    var fiscalyear = "";
    if ((date.getMonth() + 1) <= 3) {
      fiscalyear = (date.getFullYear() - 1) + "-" + date.getFullYear()
    } else {
      fiscalyear = date.getFullYear() + "-" + (date.getFullYear() + 1)
    }
    return fiscalyear
  }

}

export default Helper;