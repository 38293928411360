<template>
  <div class="app-bar">
    <v-app-bar
      app
      elevation="1"
      color="white"
    >
      <div>
        <v-img
          height="40px"
          width="40px"
          :src="currentInstitution.logo"
        ></v-img>
      </div>
      <h3 class="ml-4 hidden-md-and-down">
        {{ currentInstitution.name }}
      </h3>

      <v-spacer></v-spacer>
      <!-- <v-spacer></v-spacer> -->

      <!-- <v-autocomplete
      v-if="!isSuperInstitution && !currentInstitution.stage"
        flat
        :items="searchItems"
        solo
        rounded
        background-color="#f6f6f6"
        light
        hide-details
        hide-no-data
        item-text="title"
        item-value="title"
        v-model="searchText"
        autocomplete="off"
        prepend-inner-icon="mdi-magnify"
        label="Search ( Ctrl + / )"
        ref="searchInputBox"
        class="hidden-sm-and-down"
        id="appbar-search-bar"
      >
        <template v-slot:item="data">
          <template>
            <v-list-item-content>
              <v-list-item-title v-html="data.item.title"></v-list-item-title>
              <v-list-item-subtitle
                v-html="data.item.breadcrumb"
              ></v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </template>
      </v-autocomplete> -->
      <!-- <v-spacer></v-spacer> -->

      <!-- support options icon -->
      <!-- <v-icon
        title="Support"
        color="black"
        @click="supportOptionsDialog = true"
        id="headset"
      >
        mdi-headset
      </v-icon>   -->

      <!-- settings icon -->
      <!-- <v-icon 
        title="Settings" 
        v-if="currentUser.is_admin && !isSuperInstitution && !currentInstitution.stage" 
        color="black" 
        @click="$router.push({name: 'InstitutionSettings'})" 
        class="mr-2 ml-5"
        id="cog"
      >
        mdi-cog
      </v-icon> -->

      <!-- task drawer icon -->
      <!-- <v-icon 
        v-if="JSON.parse(currentInstitution.erp_features).includes(12) && currentUser.privileges.canViewOwnTasks && !currentInstitution.stage" 
        title="Tasks" 
        color="black" 
        class="mr-2 ml-3" 
        @click="showTaskDrawer = !showTaskDrawer"
        :disabled="$route.name == 'AllTasks'"
      >
        mdi-calendar-clock
      </v-icon> -->

      <v-menu
        v-model="value"
        :disabled="false"
        :absolute="false"
        :open-on-hover="false"
        :close-on-click="true"
        :close-on-content-click="true"
        :offset-x="false"
        :offset-y="true"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            data-cy="app-bar-dropdown-options-list"
            large
            color="#ffffff26"
            v-bind="attrs"
            v-on="on"
            text
            id="profile-actions"
          >
            <h4 class="font-weight-black black--text">
              {{ currentUser.full_name }}
            </h4>
            <v-avatar size="32px" class="ml-2" item>
              <v-img :src="currentUser.image" alt="User Profile Image"></v-img>
            </v-avatar>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            v-for="(item, index) in items"
            :key="index"
            @click="() => handleProfileAction(item.title)"
          >
            <v-list-item-title :data-cy="item.identifier">{{
              item.title
            }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      
      <v-progress-linear
        indeterminate
        v-if="isLoading"
        color="primary"
        background-color="#f0f7f7"
        style="
           {
            z-index: 100;
          }
        "
        fixed
        bottom
      ></v-progress-linear>
    </v-app-bar>

    <!-- support options dialog -->
    <!-- <SupportOptionsDialog
      :visible="supportOptionsDialog"
      @closeSupportOptionsDialog="closeSupportOptionsDialog"
    /> -->

    <change-password-dialog
      :visible="passwordChangeDialog"
      @passwordChangeSuccessful="passwordChangeSuccessful"
      @closeChangePasswordDialog="passwordChangeDialog = false"
    />

    <!-- <ConfirmationDialog
      title="Check-out Warning!"
      description="You have an active attendance entry that is pending to be checked-out."
      successText="Proceed"
      @successCallback="virtualTap"
      failureText="Cancel"
      :disableSuccessButton="!checkoutForm"
      @failureCallback="logoutDespiteActiveEntryConfirmation = false"
      :visible="logoutDespiteActiveEntryConfirmation"
    >
      <div class="mx-8">
        <v-form v-model="checkoutForm" ref="checkoutForm">
          <v-checkbox
            class="my-0"
            v-model="checkoutCheckbox"
            label="Check-out active attendance entry"
          ></v-checkbox>
        </v-form>
      </div>
    </ConfirmationDialog>

    <TaskManagement
      :drawer="showTaskDrawer"
      @closeTaskDrawer="closeTaskDrawer"
    /> -->

    <v-snackbar :color="snackbar.color" v-model="snackbar.visible">
      <span>{{ snackbar.text }}</span>
      <template>
        <v-btn color="black" text @click="snackbar.visible = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
// import searchItems from "@utils/search_items";
import ChangePasswordDialog from "./auth/ChangePasswordDialog.vue";
// import ConfirmationDialog from "@components/widgets/dialogs/ConfirmationDialog";
import api from "@api/index";
import endpoints from "@api/repository";
// import SupportOptionsDialog from "@components/widgets/dialogs/SupportOptionsDialog.vue";
// import TaskManagement from "@components/task/TaskManagement.vue";
// import TourHelper from "@utils/tour_helper";

export default {
  name: "AppBar",
  components: {
    ChangePasswordDialog,
    // ConfirmationDialog,
    // TaskManagement,
    // SupportOptionsDialog,
  },

  computed: {
    ...mapGetters([
      "currentUser",
      "isLoading",
      "drawer",
      "accessToken",
      "isLoading",
      "currentInstitution",
    ]),

    apiEssentials() {
      return {
        accessToken: this.accessToken,
        handleErrorsFunction: this.handleApiErrors,
        setLoading: this.setLoading,
      };
    },
  },

  props: ["isSuperInstitution"],

  data() {
    return {
      rules: {
        required: (value) => !!value || "Required",
        numbersOnly: (v) => !v || !/[^0-9]/.test(v) || "Invalid",
        phoneNumberLength: (v) =>
          !v || v?.length == 10 || "Should be 10 Digits",
      },
      logoutDespiteActiveEntryConfirmation: false,
      checkoutCheckbox: true,
      checkoutForm: false,
      searchText: null,
      // searchItems: searchItems,

      passwordChangeDialog: false,
      supportOptionsDialog: false,
      requestSupportDialog: false,
      submitForm: false,

      showTaskDrawer: false,

      supportRequestBody: {
        id: null,
        full_name: null,
        phone: null,
        message: null,
      },

      items: [
        { title: "Change Password", identifier: "app-bar-change-button" },
        { title: "Rewatch Tutorial", identifier: "app-bar-rewatch-button" },
        { title: "Logout", identifier: "app-bar-logout-button" },
      ],
      value: false,
      snackbar: {
        text: null,
        visible: false,
        color: null,
      },
    };
  },
  methods: {
    ...mapActions(["toggleDrawer"]),
    ...mapActions(["setLoading"]),

    async handleApiErrors(err) {
      this.setLoading(false);
      console.log(err);
      if (err.response) {
        if (err.response.status == 409) {
          this.snackbar.text = err.response.data;
          this.snackbar.color = "error";
          this.snackbar.visible = true;
        }
      } else {
        this.snackbar.text = "Unable to process request";
        this.snackbar.color = "error";
        this.snackbar.visible = true;
      }
    },

    async virtualTap() {
      if (this.checkoutCheckbox) {
        await api.call(
          this.apiEssentials,
          endpoints.biometricTap,
          api.Methods.POST,
          { staff_id: this.currentUser.id }
        );
      }
      this.logoutDespiteActiveEntryConfirmation = false;
      this.pushToLogout();
    },

    async getActiveEntry() {
      var response = await api.call(
        this.apiEssentials,
        endpoints.getActiveEntry
      );
      console.log(response);
      if (response != null && response.status != 480) {
        this.entry = response;
        this.accountExists = true;
        this.getAllEntries();
      } else {
        this.accountExists = false;
      }
    },

    async checkAttendanceEntryActive() {
      var entry = await api.call(this.apiEssentials, endpoints.getActiveEntry);
      if (entry) {
        this.logoutDespiteActiveEntryConfirmation = true;
      } else {
        this.pushToLogout();
      }
    },

    // executed when clicked on an action in the profile icon dropdown
    async handleProfileAction(title) {
      if (title === "Change Password") {
        this.passwordChangeDialog = true;
      }
      if (title === "Rewatch Tutorial") {
        // var success = await TourHelper.resetTour();
        // if (success) {
        //   this.snackbar.text = "Tutorial has been successfully reset!";
        //   this.snackbar.color = "success";
        //   this.snackbar.visible = true;
        // }
        
      }
      if (title === "Logout") {
        this.checkAttendanceEntryActive();
      }
    },

    pushToLogout() {
      this.$router.push("/logout");
      this.searchText = "";
    },

    async passwordChangeSuccessful() {
      this.passwordChangeDialog = false;
      this.snackbar.text = "Password changed successfully!";
      this.snackbar.color = "success";
      this.snackbar.visible = true;
    },

    closeSupportOptionsDialog() {
      this.supportOptionsDialog = false;
    },

    triggerSupportRequest() {
      this.supportRequestBody.id = this.currentUser.id;
      this.supportRequestBody.full_name = this.currentUser.full_name;
      this.supportRequestBody.phone = this.currentUser.phone;
      this.requestSupportDialog = true;
    },

    async submitSupportRequest() {
      var url = endpoints.requestSupport;

      var response = await api.call(
        this.apiEssentials,
        url,
        api.Methods.POST,
        this.supportRequestBody
      );
      if (response) {
        this.snackbar.text =
          "Your Support Request has been raised. Our support team will get in touch with you soon";
        this.snackbar.visible = true;
        this.snackbar.color = "success";
        this.closeSupportRequestDialog();
        this.closeSupportOptionsDialog();
      }
    },

    closeSupportRequestDialog() {
      this.requestSupportDialog = false;
      this.supportRequestBody.message = null;
      this.$refs.form.resetValidation();
    },

    closeTaskDrawer(showDrawer) {
      this.showTaskDrawer = showDrawer;
    },
  },

  watch: {
    // searchText() {
    //   var item = searchItems.filter((i) => i.title == this.searchText)[0];
    //   if (!item) return;
    //   this.$router.push(item.link);
    //   this.searchText = "";
    // },
  },

  mounted() {
    //listens to 's' button for searching
    this._keyListener = function (e) {
      if (e.key === "/" && (e.ctrlKey || e.metaKey)) {
        e.preventDefault();
        this.$refs.searchInputBox.focus();
      }
    };

    document.addEventListener("keydown", this._keyListener.bind(this));

    // hide 'rewatch tutorial' option until setup is complete
    if(this.currentInstitution && this.currentInstitution.stage) {
      this.items = this.items.filter(item => item.title != "Rewatch Tutorial")
    }
  },

  beforeDestroy() {
    document.removeEventListener("keydown", this._keyListener);
  },
};
</script>

<style lang="css">
.text-color-white {
  margin: 0px !important;
  padding: 0px;
  color: white;
}
.v-list-item__content {
  text-align: start;
}
</style>

<style scoped>
/* @import "~shepherd.js/dist/css/shepherd.css"; */

.v-list-item__content {
  max-width: 350px;
}
.wrap-text {
  -webkit-line-clamp: unset !important;
  white-space: normal;
}
</style>