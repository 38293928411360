const SERVER_URL = "https://app.intractapp.in/";
// const SERVER_URL = "http://localhost:8000/";
// const SERVER_URL = "http://app.intractapp.in:8000/";


const endpoints = {
	
    // Auth endpoints
    obtainJWT: SERVER_URL + 'jauth/jwt/create/',
	refreshJWT: SERVER_URL + 'jauth/jwt/refresh/',
	authenticateSubordinateUser: SERVER_URL + 'base/users/subordinate/authenticate/',
	getMyDetails: SERVER_URL + 'base/users/me/',
	getInstitutionDetails: SERVER_URL + 'base/institution/id/',
	getRoomFromSections: SERVER_URL + 'base/rooms/get/',
	checkInstitutionCode: SERVER_URL + 'base/institution/code/check/',
	getInstitutionDetailsByCode: SERVER_URL + 'base/institution/code/',
	getInstitutionDetailsById: SERVER_URL + 'base/institution/id/',
	defaultProfileImage: SERVER_URL + 'media/users/default/default.jpg',

	requestSupport: SERVER_URL + 'support/request/create/',

	tourHistory: SERVER_URL + 'onboarding/tour/history/',

	// Departments
	getDepartments: SERVER_URL + 'base/departments/',

	changePassword: SERVER_URL + 'erp/staff/password/change/',
	sendPasswordResetEmail: SERVER_URL + 'erp/staff/password/reset/email/',
	verifyResetPasswordToken: SERVER_URL + 'erp/staff/password/reset/token/verify/',
	resetPassword: SERVER_URL + 'erp/base/user/password/reset/',
	resetStaffPassword: SERVER_URL + 'erp/base/staff/password/reset/',
	
	institutionsViewSet: SERVER_URL + 'base/institutions/',

	// ROOMS
	getAllClassRooms: SERVER_URL + 'erp/classrooms/all/',
	getClassRoomDetails: SERVER_URL + 'erp/classrooms/details/',
	getClassRoomStudents: SERVER_URL + 'erp/classrooms/students/',
	editClassRoom: SERVER_URL + 'erp/classrooms/edit/',

	getAllRooms: SERVER_URL + 'erp/rooms/',

	// STUDENTS
	getAllStudents: SERVER_URL + 'erp/students/all/',
	getStudentDetails: SERVER_URL + 'erp/students/details/',
	createOrEditStudent: SERVER_URL + 'erp/students/create_or_edit/',
	getStudentAttributes: SERVER_URL + 'erp/students/custom_attributes/',
	getRooms: SERVER_URL + 'erp/rooms/',
	refundFeesForChangingRoom: SERVER_URL + 'erp/students/rooms/change/refund/',
	checkUsernameExists: SERVER_URL + "base/user/check_username_exists/",
	softDeleteStudent: SERVER_URL + "erp/students/delete/soft/",
	deleteCurrentStudentRecord: SERVER_URL + "erp/students/delete/current/",
	deletedStudents: SERVER_URL + 'base/students/deleted/',
	deletedCurrentStudentRecords: SERVER_URL + 'base/students/current/deleted/',


	// FEES

	// Fee Structure
	getBasicStructureDetails: SERVER_URL + 'erp/fees/structure/base/details/',
	saveParticularInstalment: SERVER_URL + 'erp/fees/structure/particulars/instalments/save/',
	deleteParticularInstalment: SERVER_URL + 'erp/fees/structure/particulars/instalments/delete/',
	
	// Fee Record
	
	getFeeStudentProfileDetails: SERVER_URL + 'erp/fees/students/details/basic/',
	getFeeStudentPaymentPlan: SERVER_URL + 'erp/fees/students/payment_plan/',
	getFeeStudentAccountDetails: SERVER_URL + 'erp/fees/students/account/details/',
	getFeeStudentPaymentHistory: SERVER_URL + 'erp/fees/students/payment/history/',
	getFeeReceiptDetails: SERVER_URL + 'erp/fees/students/receipt/',
	
	// Fee Collection
	getStudentDataForFeeCollection: SERVER_URL + 'erp/fees/collect/students/data/',
	saveOnlinePaymentDetails : SERVER_URL + 'fees/portal/savepaymentdetails/',
	// Fee Receipt settings
	getFeeReceiptSettings : SERVER_URL + 'erp/fees/receipt/settings/get/',
	// FeeRefund
	getFeeReundReceiptDetails: SERVER_URL + 'erp/fees/refund/student/receipt/',

	// FeeSessions
	getFeeSessions: SERVER_URL + "erp/fees/sessions/",

	// Fee Portal Preferences
	getFeePortalPreference: SERVER_URL + "fees/portal/preference/",
}

export default endpoints;  