import Vue from 'vue';
import VueRouter from 'vue-router'
// import Helper from '@utils/misc';


Vue.use(VueRouter)

function lazyLoad(viewPath) {
    return () => import(`@views/${viewPath}`);
}

const routes = [
    { path: '/login/institute', name: 'LoginInstitute', component: lazyLoad('auth/LoginInstitute.vue'), meta: { title: 'Login Institute' } },
    { path: '/login/user/:code', name: 'LoginUser', component: lazyLoad('auth/LoginUser.vue'), meta: { title: 'Login User' } },
    { path: '/logout', name: 'Logout', component: lazyLoad('auth/Logout.vue'), meta: { title: 'Logout' } },
    { path: '/restricted', name: 'Restricted', component: lazyLoad('auth/Restricted.vue'), meta: { title: 'Restricted' } },
    { path: '/password/reset', name: 'ResetPassword', component: lazyLoad('auth/ResetPassword.vue'), meta: { title: 'Reset Password' }, props:true },
  
    // { path: "*", component: lazyLoad('default/PageNotFound.vue'), meta: { title: 'Page Not Found' } },
    { path: '/', name: 'FeeDashboard', component: lazyLoad('FeeDashboard.vue'), meta: { title: 'Fee Dashboard' } },
    { path: '/pay', name: 'PayFee', component: lazyLoad('PayFee.vue'), props: true, meta: { title: 'Pay Fees'} },
    { path: '/receipt/:sid/:rid', name: 'ViewFeesPaid', component: lazyLoad('ViewFeesPaid.vue'), meta: { title: 'Student Fee Receipt'} },
    { path: '/print/:rid/:count', name: 'ViewFeeReceipt', component: lazyLoad('ViewFeeReceipt.vue'), meta: { title: 'Print Fee Receipt'}}


]

const router = new VueRouter({
    routes,
    mode: 'history'
})

router.beforeEach((to, from, next) => {
    const publicPages = ['/login/institute', '/login/user/*', '/password/reset', '/restricted'];
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));

    // trying to access a restricted page + not logged in
    // redirect to login page
    if (!currentUser) {
      const authRequired = !publicPages.some(page => RegExp(page).test(to.path));
      if (authRequired) {
        next('/login/institute' + "?next=" + to.path);
  
      } else next();
    } else {      
      // Helper.updateQuickLinks(to);
  
      // check privilege
      if(!to.meta.requiredPermission || currentUser.privileges[to.meta.requiredPermission]) next();
      else router.push(`/restricted?from=${to.path}`);
    }
  });
  
  export default router